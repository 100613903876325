var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"interaction-question"},[_c('div',{staticClass:"interaction-question__title",domProps:{"innerHTML":_vm._s(_vm.question.title)}}),_vm._l((_vm.question.choices),function(choice,aInd){return _c('div',{key:aInd,staticClass:"interaction-question__answer"},[_c('div',{staticClass:"interaction-question__answer-label"},[_c('div',{staticClass:"interaction-question__answer-label-number"},[_vm._v(" "+_vm._s(aInd + 1)+" ")]),_c('div',{staticClass:"interaction-question__answer-label-text",domProps:{"innerHTML":_vm._s(choice.title)}})]),_c('div',{staticClass:"interaction-question__answer-values"},_vm._l((5),function(j){return _c('div',{key:j,staticClass:"interaction-question__answer-value",class:{
          'interaction-question__answer-value_current':
            _vm.answers[aInd] && _vm.answers[aInd].points === j,
          'interaction-question__answer-value_disable':
            _vm.answers.findIndex(function (el) { return el && el.points && el.points === j; }) !==
              aInd &&
            _vm.answers.findIndex(function (el) { return el && el.points && el.points === j; }) >
              -1,
        },on:{"click":function($event){return _vm.setAnswer(aInd, _vm.question.id, choice.id, j)}}},[_vm._v(" "+_vm._s(j)+" ")])}),0)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }