<template>
  <div class="interaction-question">
    <div class="interaction-question__title" v-html="question.title"></div>
    <div
      class="interaction-question__answer"
      v-for="(choice, aInd) in question.choices"
      :key="aInd"
    >
      <div class="interaction-question__answer-label">
        <div class="interaction-question__answer-label-number">
          {{ aInd + 1 }}
        </div>
        <div
          class="interaction-question__answer-label-text"
          v-html="choice.title"
        ></div>
      </div>
      <div class="interaction-question__answer-values">
        <div
          class="interaction-question__answer-value"
          v-for="j in 5"
          :key="j"
          :class="{
            'interaction-question__answer-value_current':
              answers[aInd] && answers[aInd].points === j,
            'interaction-question__answer-value_disable':
              answers.findIndex((el) => el && el.points && el.points === j) !==
                aInd &&
              answers.findIndex((el) => el && el.points && el.points === j) >
                -1,
          }"
          @click="setAnswer(aInd, question.id, choice.id, j)"
        >
          {{ j }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InteractionQuestion",
  props: {
    question: Object,
  },
  data: () => ({
    answers: [null, null, null, null, null],
  }),
  methods: {
    setAnswer(ind, questionId, choiceId, points) {
      if (this.answers.find((el) => el && el.points && el.points == points)) {
        this.$set(this, "answers", [null, null, null, null, null]);
      }
      this.$set(this.answers, ind, {
        question: questionId,
        choice: choiceId,
        points: points,
      });
      this.$emit('setQuestionChoices', this.answers)
    },
  },
};
</script>

<style lang="scss" scoped>
.interaction-question {
  &__title {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #1f1f1f;
  }

  &__answer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 767px) {
      display: block;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &-label {
      gap: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;

      @media screen and (max-width: 767px) {
        align-items: flex-start;
      }
    }

    &-values {
      margin-left: auto;
      display: grid;
      grid-template-columns: repeat(5, 42px);
      direction: rtl;
      @media screen and (max-width: 767px) {
        margin-top: 16px;
        grid-template-columns: repeat(5, 1fr);
      }
    }

    &-value {
      aspect-ratio: 1/1;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #000;
      cursor: pointer;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #000000;

      @media screen and (max-width: 767px) {
        font-size: 24px;
      }

      &_disable {
        opacity: 0.4;
      }
      &_current {
        &:nth-child(5) {
          background-color: #fd4f51;
        }
        &:nth-child(4) {
          background-color: #fdba4f;
        }
        &:nth-child(3) {
          background-color: #fdf44f;
        }
        &:nth-child(2) {
          background-color: #caf35a;
        }
        &:nth-child(1) {
          background-color: #31cc20;
        }
      }
      &:nth-child(5) {
        border-color: #fd4f51;
      }
      &:nth-child(4) {
        border-color: #fdba4f;
      }
      &:nth-child(3) {
        border-color: #fdf44f;
      }
      &:nth-child(2) {
        border-color: #caf35a;
      }
      &:nth-child(1) {
        border-color: #31cc20;
      }
    }
  }
}
</style>