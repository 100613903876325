<template>
  <div
    class="interaction"
    v-if="questionnaire && Object.keys(questionnaire).length"
  >
    <div class="content">
      <div class="interaction__row">
        <div>
          <div class="interaction__block">
            <div class="interaction__block-head">
              <div class="interaction__block-title">
                Взаимодействие с представителями фармацевтических компаний
              </div>
              <div class="interaction__block-description" v-if="!finish">
                Оцените каждое утверждение по 5-балльной шкале, где
                <span style="color: #fd4f51">5 - наименее важно</span>,
                <span style="color: #31cc20">1 - наиболее важно</span>. Оценки
                не должны повторяться. Каждое утверждение должно быть оценено
              </div>
            </div>
            <div class="interaction__block-progress" v-if="!finish">
              <div class="interaction__block-progress-row">
                <div
                  class="interaction__block-progress-item"
                  v-for="(question, qInd) in questionnaire.questions"
                  :key="qInd"
                  :class="{
                    'interaction__block-progress-item_passed':
                      activeQuestion > qInd,
                  }"
                >
                  <div
                    class="interaction__block-progress-item-number"
                    :class="{
                      'interaction__block-progress-item-number_active':
                        activeQuestion === qInd,
                      'interaction__block-progress-item-number_passed':
                        activeQuestion > qInd,
                    }"
                  >
                    {{ qInd + 1 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="interaction__block-test" v-if="!finish">
              <InteractionQuestion
                :question="question"
                v-for="(question, qInd) in questionnaire.questions"
                :key="qInd"
                v-show="activeQuestion === qInd"
                @setQuestionChoices="setQuestionChoices($event, qInd)"
              />
            </div>
            <div class="interaction__block-finish" v-if="finish">
              <div class="interaction__block-finish-text">
                <div class="interaction__block-finish-title">
                  Спасибо за Ваше мнение!
                </div>
                <div class="interaction__block-finish-description">
                  В благодарность за участие в опросе отправляем Вам специальное
                  кодовое слово для регистрации в закрытом Клубе
                  онкогематологов!
                  <br />
                  <br />
                  После перехода по ссылке необходимо будет пройти быструю
                  авторизацию на сайте AZ-MOST, после чего ввести в появившемся
                  окне слово: <b>TOPSECRET</b>
                </div>
              </div>
              <a
                href="https://az-most.ru/education/klub-onkogematologov"
                v-if="finish"
                onclick="ym(91468266,'reachGoal','club')"
                class="mx-auto mt-4 button button_pink-light"
              >
                Перейти
                <svg
                  class="ml-2"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 4.50012L16.5 12.0001L9 19.5001"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                </svg>
              </a>
            </div>
            <div class="interaction__block-buttons" v-if="!finish">
              <div
                v-if="activeQuestion !== 0"
                @click="prevQuestion()"
                class="interaction__block-button_back button button_empty-pink"
              >
                <svg
                  class="mr-2"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 4.50012L7.5 12.0001L15 19.5001"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                </svg>
                Назад
              </div>
              <div
                @click="nextQuestion()"
                :class="{
                  button_disabled:
                    !result.answers[activeQuestion] ||
                    result.answers[activeQuestion].filter((el) => el).length !==
                      questionnaire.questions[activeQuestion].choices.length,
                }"
                class="interaction__block-button_next button button_pink-light"
              >
                {{
                  activeQuestion + 1 == questionnaire.questions.length
                    ? "Завершить"
                    : "Далее"
                }}
                <svg
                  class="ml-2"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 4.50012L16.5 12.0001L9 19.5001"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                </svg>
              </div>
            </div>
          </div>
          <hr class="mt-4" style="color: #424753; opacity: 0.3" />
        </div>
        <div class="sticky">
          <RightAside
            v-if="asideItems && asideItems.length"
            :items="asideItems"
            @medicationsClick="medicationsClick"
            @calendarClick="calendarClick"
            @libClick="libClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InteractionQuestion from "@/components/interaction/InteractionQuestion.vue";
import RightAside from "@/components/pageComponents/RightAside.vue";
export default {
  name: "Interaction",
  components: {
    InteractionQuestion,
    RightAside,
  },
  data: () => ({
    activeQuestion: 0,
    questionnaire: {},
    result: {
      questionnaire: null,
      answers: [],
    },
    finish: false,
  }),
  computed: {
    asideItems() {
      return [
        {
          title: "Препараты АЗ",
          // descr: this.Disease.parent.title,
          image_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-1_small.jpg`,
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-1.jpg`,
          theme: "white",
          event: "medicationsClick",
          to: {
            name: "Medications",
          },
        },
        {
          title: "Мероприятия",
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-2.jpg`,
          image_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-2_small.jpg`,
          links: [
            {
              event: "calendarClick",
              title: "Предстоящие",
              to: {
                name: "Events",
              },
            },
            {
              event: "calendarClick",
              title: "Архив",
              to: {
                name: "ArchiveEvents",
              },
            },
          ],
        },
        {
          title: "Библиотека",
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-3.jpg`,
          image_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-3_small.jpg`,
          links: [
            {
              event: "libClick",
              title: "Публикации",
              to: {
                name: "TextMaterials",
              },
            },
            {
              event: "libClick",
              title: "Видеоматериалы",
              to: {
                name: "VideoMaterials",
              },
            },
            {
              event: "libClick",
              title: "Подкасты",
              to: {
                name: "Podcasts",
              },
            },
          ],
          theme: "dark",
        },
      ];
    },
  },
  methods: {
    prevQuestion() {
      this.activeQuestion--;
    },
    nextQuestion() {
      if (this.activeQuestion + 1 == this.questionnaire.questions.length) {
        const result = { ...this.result, answers: this.result.answers.flat() };
        if (this.$route.query.id) {
          result.deeplink_user = this.$route.query.id;
        }
        this.$axios("api/user/questionnaire-result", {
          method: "POST",
          data: result,
        }).then((res) => {
          this.$set(this, "finish", true);
          localStorage.setItem("interactionFinish", "true");
        });
      } else {
        this.activeQuestion++;
      }
    },
    medicationsClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "interaction page medications click", {
          "interaction page medications click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    calendarClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "interaction page events click", {
          "interaction page events click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    libClick(item, text) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "interaction page library materails click", {
          "interaction page library materails click": {
            "block name": text,
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    getQuestionnaire() {
      this.$axios("api/user/questionnaire/form1/", {
        method: "GET",
      }).then((res) => {
        const result = res.data;
        this.$set(this, "questionnaire", result);
        this.$set(this.result, "questionnaire", result.slug);
      });
    },
    setQuestionChoices(answers, qInd) {
      this.$set(this.result.answers, qInd, answers);
    },
  },
  mounted() {
    this.getQuestionnaire();
    if (localStorage.getItem("interactionFinish")) {
      this.$set(this, "finish", true);
    }
  },
};
</script>

<style lang="scss" scoped>
.interaction {
  margin-top: 32px;
  &__row {
    display: grid;

    column-gap: 136px;
    grid-template-columns: 2fr 280px;
    @media screen and (max-width: 1220px) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
    .failure-page__aside {
      @media screen and (max-width: 1220px) {
        display: flex;
        overflow-x: auto;
        align-items: center;
        justify-content: flex-start;

        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
      }
    }
  }
  &__block {
    border: 3px solid #eaf7f8;
    border-radius: 8px;
    padding-bottom: 16px;
    height: fit-content;

    &-head {
      padding: 24px 16px;
      background-color: #eaf7f8;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #00aeca;
    }

    &-description {
      margin-top: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #3c4242;
    }

    &-progress {
      padding: 40px 30px;
      @media screen and (max-width: 767px) {
        padding: 24px 16px;
      }

      &-row {
        display: grid;
        grid-template-columns: repeat(9, 1fr) 23px;
      }

      &-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:not(:last-child) {
          &::after {
            width: 100%;
            height: 1px;
            background-color: #eaf7f8;
            content: "";
            display: block;
          }
        }
        &_passed {
          &::after {
            background-color: #00aeca !important;
          }
        }
        &-number {
          flex-shrink: 0;
          border-radius: 50%;
          width: 23px;
          height: 23px;
          border: 1px solid #eaf7f8;
          background-color: #eaf7f8;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 13px;
          color: #b2b4b4;
          display: flex;
          justify-content: center;
          align-items: center;

          &_active {
            border-color: #00aeca;
            color: #3c4242;
          }

          &_passed {
            background-color: #00aeca;
            font-size: 0;
            &::before {
              width: 11px;
              height: 9px;
              background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.20012L3.57143 8.00012L10 1.00012' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              content: "";
              display: block;
            }
          }
        }
      }
    }

    &-test {
      margin: 0 4px;
      padding: 16px;
      background-color: #f8f8f8;
    }

    &-buttons {
      margin: 24px 4px 0;
      display: flex;
      gap: 16px;
    }

    &-finish {
      margin-top: 40px;
      &-text {
        background-color: #eaf7f8;
        padding: 16px clamp(24px, 20%, 100px);
        margin: 0 8px;
        @media screen and (max-width: 767px) {
          padding: 16px 24px;
        }
      }

      &-title {
        margin-bottom: 16px;
        font-family: "Roboto Slab", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 29px;
        text-align: center;
        color: #1f1f1f;
      }

      &-description {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #3c4242;
        & b {
          font-weight: 700;
        }
      }
    }
  }
}
</style>