var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.questionnaire && Object.keys(_vm.questionnaire).length)?_c('div',{staticClass:"interaction"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"interaction__row"},[_c('div',[_c('div',{staticClass:"interaction__block"},[_c('div',{staticClass:"interaction__block-head"},[_c('div',{staticClass:"interaction__block-title"},[_vm._v(" Взаимодействие с представителями фармацевтических компаний ")]),(!_vm.finish)?_c('div',{staticClass:"interaction__block-description"},[_vm._v(" Оцените каждое утверждение по 5-балльной шкале, где "),_c('span',{staticStyle:{"color":"#fd4f51"}},[_vm._v("5 - наименее важно")]),_vm._v(", "),_c('span',{staticStyle:{"color":"#31cc20"}},[_vm._v("1 - наиболее важно")]),_vm._v(". Оценки не должны повторяться. Каждое утверждение должно быть оценено ")]):_vm._e()]),(!_vm.finish)?_c('div',{staticClass:"interaction__block-progress"},[_c('div',{staticClass:"interaction__block-progress-row"},_vm._l((_vm.questionnaire.questions),function(question,qInd){return _c('div',{key:qInd,staticClass:"interaction__block-progress-item",class:{
                  'interaction__block-progress-item_passed':
                    _vm.activeQuestion > qInd,
                }},[_c('div',{staticClass:"interaction__block-progress-item-number",class:{
                    'interaction__block-progress-item-number_active':
                      _vm.activeQuestion === qInd,
                    'interaction__block-progress-item-number_passed':
                      _vm.activeQuestion > qInd,
                  }},[_vm._v(" "+_vm._s(qInd + 1)+" ")])])}),0)]):_vm._e(),(!_vm.finish)?_c('div',{staticClass:"interaction__block-test"},_vm._l((_vm.questionnaire.questions),function(question,qInd){return _c('InteractionQuestion',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeQuestion === qInd),expression:"activeQuestion === qInd"}],key:qInd,attrs:{"question":question},on:{"setQuestionChoices":function($event){return _vm.setQuestionChoices($event, qInd)}}})}),1):_vm._e(),(_vm.finish)?_c('div',{staticClass:"interaction__block-finish"},[_vm._m(0),(_vm.finish)?_c('a',{staticClass:"mx-auto mt-4 button button_pink-light",attrs:{"href":"https://az-most.ru/education/klub-onkogematologov","onclick":"ym(91468266,'reachGoal','club')"}},[_vm._v(" Перейти "),_c('svg',{staticClass:"ml-2",attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9 4.50012L16.5 12.0001L9 19.5001","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"square"}})])]):_vm._e()]):_vm._e(),(!_vm.finish)?_c('div',{staticClass:"interaction__block-buttons"},[(_vm.activeQuestion !== 0)?_c('div',{staticClass:"interaction__block-button_back button button_empty-pink",on:{"click":function($event){return _vm.prevQuestion()}}},[_c('svg',{staticClass:"mr-2",attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M15 4.50012L7.5 12.0001L15 19.5001","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"square"}})]),_vm._v(" Назад ")]):_vm._e(),_c('div',{staticClass:"interaction__block-button_next button button_pink-light",class:{
                button_disabled:
                  !_vm.result.answers[_vm.activeQuestion] ||
                  _vm.result.answers[_vm.activeQuestion].filter(function (el) { return el; }).length !==
                    _vm.questionnaire.questions[_vm.activeQuestion].choices.length,
              },on:{"click":function($event){return _vm.nextQuestion()}}},[_vm._v(" "+_vm._s(_vm.activeQuestion + 1 == _vm.questionnaire.questions.length ? "Завершить" : "Далее")+" "),_c('svg',{staticClass:"ml-2",attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9 4.50012L16.5 12.0001L9 19.5001","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"square"}})])])]):_vm._e()]),_c('hr',{staticClass:"mt-4",staticStyle:{"color":"#424753","opacity":"0.3"}})]),_c('div',{staticClass:"sticky"},[(_vm.asideItems && _vm.asideItems.length)?_c('RightAside',{attrs:{"items":_vm.asideItems},on:{"medicationsClick":_vm.medicationsClick,"calendarClick":_vm.calendarClick,"libClick":_vm.libClick}}):_vm._e()],1)])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"interaction__block-finish-text"},[_c('div',{staticClass:"interaction__block-finish-title"},[_vm._v(" Спасибо за Ваше мнение! ")]),_c('div',{staticClass:"interaction__block-finish-description"},[_vm._v(" В благодарность за участие в опросе отправляем Вам специальное кодовое слово для регистрации в закрытом Клубе онкогематологов! "),_c('br'),_c('br'),_vm._v(" После перехода по ссылке необходимо будет пройти быструю авторизацию на сайте AZ-MOST, после чего ввести в появившемся окне слово: "),_c('b',[_vm._v("TOPSECRET")])])])}]

export { render, staticRenderFns }